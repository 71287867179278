<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Testimonial</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Testimonial"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  class="mr-2"
                  v-on="on"
                  @click="$refs.testimonialForm.openForm()"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add New Testimonial</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableHeaders"
        :items="testimonials"
        :disable-pagination="true"
        :hide-default-footer="true"
        v-sortable-data-table
        @sorted="saveOrder"
        no-data-text="There are currently no testimonials"
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="blue lighten-4 blue--text"
                v-on="on"
                class="mr-2"
                @click="$refs.testimonialForm.openForm(item)"
              >
                <v-icon x-small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="red lighten-4 red--text"
                v-on="on"
                @click="openDeleteTestimonial(item)"
              >
                <v-icon x-small>mdi-archive</v-icon>
              </v-btn>
            </template>
            <span>Archive</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>
    <TestimonialForm ref="testimonialForm" />
    <v-dialog v-model="deleteTestimonial.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Delete Testimonial</v-card-title>
        <v-card-text
          >Are you sure you wish to delete
          {{ deleteTestimonial.testimonial.title }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDeleteTestimonial"
            >No, cancel</v-btn
          >
          <v-btn
            color="success"
            depressed
            :loading="deleteTestimonial.loading"
            @click="saveDeleteTestimonial"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TestimonialForm from "./components/TestimonialForm";
import Sortable from "sortablejs";

export default {
  components: {
    TestimonialForm,
  },

  data() {
    return {
      searchTerm: "",
      breadcrumbs: [
        {
          text: "Testimonials",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Name", value: "name" },
        { text: "Company", value: "company" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      deleteTestimonial: {
        dialog: false,
        testimonial: {},
        loading: false,
      },
    };
  },

  computed: {
    testimonials() {
      let testimonials =
        this.$store.getters["petroshops/testimonialsStore/all"];

      if (this.searchTerm !== "") {
        testimonials = testimonials.filter((s) => {
          const title = s.title.toLowerCase();
          return title.includes(this.searchTerm.toLowerCase());
        });
      }

      return testimonials;
    },
  },

  directives: {
    sortableDataTable: {
      bind(el, binding, vnode) {
        const options = {
          animation: 150,
          onUpdate: function (event) {
            vnode.child.$emit("sorted", event);
          },
        };
        Sortable.create(el.getElementsByTagName("tbody")[0], options);
      },
    },
  },

  methods: {
    saveOrder(event) {
      const movedItem = this.testimonials.splice(event.oldIndex, 1)[0];
      this.testimonials.splice(event.newIndex, 0, movedItem);

      const displayOrder = this.testimonials.map((p) => {
        return p.id;
      });
      const appId = this.$route.params.id;

      this.$store.commit("showHideLoader", true);
      this.$store
        .dispatch(
          "petroshops/testimonialsStore/updateTestimonialsDisplayOrder",
          {
            appId,
            displayOrder,
          }
        )
        .then(() => this.$store.commit("showHideLoader", false))
        .catch(() => this.$store.commit("showHideLoader", false));
    },

    openDeleteTestimonial(testimonial) {
      this.deleteTestimonial.testimonial = testimonial;
      this.deleteTestimonial.dialog = true;
    },

    resetDeleteTestimonial() {
      this.deleteTestimonial.dialog = false;
      this.deleteTestimonial.testimonial = {};
      this.deleteTestimonial.loading = false;
    },

    saveDeleteTestimonial() {
      this.deleteTestimonial.loading = true;

      this.$store
        .dispatch("watson/testimonials/deleteTestimonial", {
          appId: this.$route.params.id,
          testimonialId: this.deleteTestimonial.testimonial.id,
        })
        .then(() => {
          this.resetDeleteTestimonial();
        })
        .catch(() => {
          this.deleteTestimonial.loading = false;
        });
    },
  },
};
</script>
