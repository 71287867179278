<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit" : "Add a new" }} Testimonial</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="testimonial-form">
          <tiptap
            v-model="fields.testimonial"
            label="Testimonial"
            class="mb-8"
          />
          <v-textarea
            label="Name *"
            v-model="fields.name"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('name')"
            :error-messages="errors['name']"
          ></v-textarea>
          <v-textarea
            label="Company *"
            v-model="fields.company"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('company')"
            :error-messages="errors['company']"
          ></v-textarea>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="testimonial-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Tiptap from "@/views/components/Tiptap.vue";

export default {
  components: {
    Tiptap,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      fields: {
        company: null,
        name: null,
        testimonial: null,
      },
      testimonial: null,
      errors: {},
    };
  },

  methods: {
    openForm: function (testimonial = null) {
      if (testimonial !== null) {
        this.isEditing = true;
        this.testimonial = testimonial;
        this.fields.company = testimonial.company;
        this.fields.name = testimonial.name;
        this.fields.testimonial = testimonial.testimonial;
      }

      this.dialog = true;
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.testimonial = null;
      this.fields.testimonial = null;
      this.fields.company = null;
      this.fields.name = null;

      this.errors = {};
    },

    saveForm: function () {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.testimonialId = this.testimonial.id;
      }

      this.$store
        .dispatch("petroshops/testimonialsStore/saveTestimonial", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },
  },
};
</script>
